<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <!-- Search -->
          <b-col
                  cols="12"
                  md="6"
          >
            <div class="d-flex align-items-center justify-content-end"   v-if="readonly!=1" >
              <b-button
                      variant="primary"
                      @click="showselproduct()"
                      v-if="getCodeLabel('setting','start_inventory')!=='1'"
              >
                <span class="text-nowrap">添加原料</span>

              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        hover
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
        v-model="datalist"
      >

        <!-- Columns -->
        <template #cell(id)="data">
          <b-link
              :to="{ name: 'apps-produceordersource-edit', query: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
          >
            #{{ data.item.id }}
          </b-link>
        </template>

        <!-- Column: Type -->
        <template #cell(${classVar}_type)="data">
          {{getCodeLabel('produceordersource_type', data.item.produceordersource_type)}}
        </template>

        <template #cell(product_id)="data">
          [#{{data.item.product_id}}]{{data.item.name}}
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
            @click="changeState(data.item)"
          >
          <b-badge
            pill
            :variant="`light-$ {getCodeColor('state', data.item.state)}`"

          >
            {{getCodeLabel('state', data.item.state)}}
          </b-badge>
          </b-link>
        </template>

        <template #cell(product_unit)="data">
          {{getCodeLabel('product_unit', data.item.product_unit)}}
        </template>

<!--        <template #cell(stock_qty)="data">
          {{data.item.ext.stock_qty }}
        </template>-->


        <template #cell(is_main)="data"  >
          <div v-if="readonly===0">
            <b-form-checkbox
                name="check-button"
                switch
                value="1"
                unchecked-value="0"
                @change="isMainChange(data.item,data.value)"
                v-model="data.item.is_main"
            ></b-form-checkbox>
          </div>
          <div v-if="readonly===1">
            {{data.item.is_main==1?'是':'否'}}
          </div>

        </template>



        <template #cell(purchase_qty)="data">
          <div style="width: 70px">
            <ValidationProvider :rules="`required${data.item.is_gift === 1 ? '' : '|positive'}`" name="预计使用数量"
                                #default="{ errors }" :vid="`purchase_qty_${data.index}`">
              <b-form-input
                      size="sm"
                      type="number"
                      v-model="data.item.purchase_qty"
                      :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>

          </div>
        </template>


        <!-- Column: Actions -->
        <template #cell(actions)="data" v-if="readonly==0">
          <b-link @click="save(data.item)">
            <feather-icon
                    icon="SaveIcon"
                    size="16"
                    class="align-middle text-body"
            />
          </b-link>
          <b-link @click="del(data.item.item_id,data.index)">
            <feather-icon
                    icon="XSquareIcon"
                    size="16"
                    class="align-middle text-body"
            />
          </b-link>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      <b-modal
              id="stockModal"
              ok-only
              ok-title="确认"
              @ok="onSelectStock"
              cancel-title="取消"
              centered
              size="xl"
              :title="'请选择'"
      >
        <stock-list
                ref="stockSelect" :warehouseid="warehouseid">
        </stock-list>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import produceordersourceUseList from './produceordersourceUseList'
import produceordersourceStore from './produceordersourceStore'
import stockList from '@/views/apps/stock/StockList'
import { useToast } from 'vue-toastification/composition'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    stockList,
  },
  methods: {
    changeState: function(data) {
      const state = 1 - data.state
      store.dispatch('produceordersource/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
  },
  data(){
    return{
      datalist:[],
      warehouseid:"",
    }
  },
  props:{
    produce_no:{
      type: String,
      default:""
    },
    readonly:{
      type: Number,
      default:0,
    },
    warehouse_id:{
      type: Number,
      default:0,
    },
    produce_id:{
      type: Number,
      default:0
    },
  },
  setup(props) {
    // Register module
    const toast = useToast()
    const readonly = props.readonly

    if (!store.hasModule('produceordersource')) store.registerModule('produceordersource', produceordersourceStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('produceordersource')) store.unregisterModule('produceordersource')
    })

    const showselproduct = function(){
      if(props.warehouse_id == 0){
        toast.error("请先选择仓库")
        return false
      }
      this.$bvModal.show('stockModal')
      this.produce_no = props.produce_no
      this.produce_id = props.produce_id
      this.warehouseid = props.warehouse_id
    }
    const onSelectStock = function (){
      let returnData = {}
      if(this.$refs.stockSelect.getChecked().length > 0){
        let stockids = "";
        let stocklockids = "";
        for(let i=0;i<this.$refs.stockSelect.getChecked().length;i++){
          returnData = this.$refs.stockSelect.getChecked()[i]
          if(returnData.stocklock_id == undefined){
            returnData.stocklock_id = 0
          }
          if(stockids === ""){
            stockids = returnData.stock_id
          }else{
            stockids += "," + returnData.stock_id
          }
          if(stocklockids === ""){
            stocklockids = returnData.stocklock_id
          }else{
            stocklockids += "," + returnData.stocklock_id
          }
        }

        store.dispatch('produceordersource/addnewpro', {produceid: this.produce_id, stockids: stockids,stocklockids: stocklockids}).then(res => {

          if(res.data.code == 0){

            refetchData()
          }else{
            toast.error(res.data.data)
          }
        })

      }
    }

    const save = function (item) {
      store.dispatch('produceordersource/updateOrderItem', {
        item:item,
        itemid: item.item_id,
        produceid:item.produce_id,
        ismain:item.is_main,
        //含税成本
        purchaseqty: parseInt(item.purchase_qty),
        trueqty: parseInt(item.true_qty),
      })
              .then(res => {
                if (res.data.code==0){
                  toast.success('数据已保存!')
                  refetchData()
                }else {
                  toast.error(res.data.data)
                  refetchData()
                }

              })
    }

    const del = async function (item_id,index) {
      let res = confirm('确定删除吗？')
      if (res) {
        const res = await store.dispatch('produceordersource/del', {
          id: item_id
        })
        if (res.data.code === 0) {
          toast.success(res.data.data)
          refetchData()
          const temp = this.dataList
          temp.splice(index,1)
          this.$emit('table', temp)
        } else {
          toast.error(res.data.data)
        }
      }

    }
    const isMainChange = function (item, value) {
      item.is_main = parseInt(value)
      store.dispatch('produceordersource/changeMain', {
        item:item,
        itemid: item.item_id,
        produceid:item.produce_id,
        ismain:item.is_main,
        //含税成本
        purchaseqty: parseInt(item.purchase_qty),
        trueqty: parseInt(item.true_qty),
      })
          .then(res => {
            if (res.data.code==0){
              toast.success('数据已保存!')
              refetchData()
            }else {
              toast.error(res.data.data)
              refetchData()
            }

          })
    }


    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = produceordersourceUseList({
      produce_no:props.produce_no,
      produce_id:props.produce_id,
      warehouse_id:props.warehouse_id,
      readonly: props.readonly
    })

    return {
      searchList,
      save,
      del,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      showselproduct,
      onSelectStock,
      isMainChange,
      // UI
     toTime,
     toDate,
      readonly,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
