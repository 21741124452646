import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/produceordersource/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/produceordersource/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/produceordersource/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/produceordersource/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/produceordersource/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    addnewpro(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/produceordersource/addnewpro', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    del(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/produceordersource/delorderitem', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateOrderItem(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/produceordersource/updateOrderItem', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeMain(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/produceordersource/changeMain', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
